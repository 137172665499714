<template>
    <CompTable ref="comp_table" title="按栏目统计栏目更新提醒数" :columns="columns" :table-api="tableApi">
        <template #operate>
            <Button type="info" @click="$refs.comp_table.export()">导出</Button>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Route from "../communitymobilization/entity/Route"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            tableApi: `/gateway/api/symanage/pc/columnTree/listColumnRemind`,
            columns: [
                {
                    title: "栏目名称及编码",
                    minWidth: 300,
                    align: "center",
                    key: ["columnName", "columnCode"],
                    render: (h, p) => {
                        return h("p", p.row.columnName + " - " + p.row.columnCode)
                    },
                },
                {
                    title: "昨日提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "yesterday",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#2faaf7",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        Route.jump("/columnreminder", {
                                            c: params.row.columnCode,
                                        })
                                    },
                                },
                            },
                            params.row.yesterday
                        )
                    },
                },
                {
                    title: "本周提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "week",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#2faaf7",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        Route.jump("/columnreminder", {
                                            c: params.row.columnCode,
                                        })
                                    },
                                },
                            },
                            params.row.week
                        )
                    },
                },
                {
                    title: "本月提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "month",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#2faaf7",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        Route.jump("/columnreminder", {
                                            c: params.row.columnCode,
                                        })
                                    },
                                },
                            },
                            params.row.month
                        )
                    },
                },
                {
                    title: "总提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "total",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#2faaf7",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        Route.jump("/columnreminder", {
                                            c: params.row.columnCode,
                                        })
                                    },
                                },
                            },
                            params.row.total
                        )
                    },
                },
                {
                    title: "最新提醒时间",
                    minWidth: 200,
                    align: "center",
                    content: p => (p.gmtCreate ? this.$core.formatDate(new Date(p.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-"),
                    render: (h, params) => {
                        return h("p", params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-")
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
